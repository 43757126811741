import 'bootstrap';
import { Splide } from '@splidejs/splide';

import '../scss/main.scss';

// Splide slider

var userLang = navigator.language || navigator.userLanguage;

// Set default labels
var prevLabel = 'Previous Image';
var nextLabel = 'Next Image';

// Check if the user's language is German
if (userLang === 'de' || userLang.startsWith('de')) {
    prevLabel = 'Vorheriges Bild';
    nextLabel = 'Nächstes Bild';
}

// Initialize Splide with dynamic labels
new Splide('.splide', {
    arrowPath: 'M9.333 1.333l18.667 18.667-18.667 18.667 1.333 1.333 20-20-20-20z',
    pagination: false,
    type: 'loop',
    i18n: {
        prev: prevLabel,
        next: nextLabel,
    }
}).mount();


jQuery(document).ready(function ($) {
    $(function() {
        $('.mejs-overlay-loading').closest('.mejs-overlay').addClass('load'); //just a helper class

        var $video = $('div.video video');
        var vidWidth = $video.attr('width');
        var vidHeight = $video.attr('height');

        $(window).resize(function() {
            var targetWidth = $(this).width(); //using window width here will proportion the video to be full screen; adjust as needed
            $('div.video, div.video .mejs-container').css('height', Math.ceil(vidHeight * (targetWidth / vidWidth)));
        }).resize();
    });
});


document.addEventListener("DOMContentLoaded", function () {
    var scrollButtons = document.querySelectorAll(".button-scroll-down");

    scrollButtons.forEach(function (button) {
        button.addEventListener("click", function () {
            var currentBlock = button.closest(".block");
            var nextBlock = currentBlock.nextElementSibling;

            if (nextBlock !== null && nextBlock.classList.contains("block")) {
                var offsetTop = nextBlock.offsetTop;
                window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth"
                });
            }
            return false;
        });
    });
});

